<template>
   <article class="pagination main__pagination">
      <div class="pagination__container container">
         <h2 class="pagination__title">Пагинация</h2>

         <ul class="pagination__list-pages list-pages">
            <li class="list-pages__item">
               <button
                  :disabled="isEnabledPrevPageBtn"
                  @click="selectPrevPage"
                  class="list-pages__btn list-pages__btn--prev btn"
               >
                  &lt;
               </button>
            </li>

            <li v-if="isEnabledQuickReturnBtn" class="list-pages__item">
               <button @click="moveBackFewPages" class="list-pages__dots btn">...</button>
            </li>

            <li v-for="page in displayedPages" :key="page" class="list-pages__item">
               <button
                  :class="{ 'list-pages__btn--active': currentPage === page }"
                  :disabled="currentPage === page"
                  @click="setPageNumber(page)"
                  class="list-pages__btn btn"
               >
                  {{ page }}
               </button>
            </li>

            <li v-if="isEnabledFastForwardBtn" class="list-pages__item">
               <button @click="moveForwardFewPages" class="list-pages__dots btn">...</button>
            </li>

            <li class="list-pages__item">
               <button
                  :disabled="isEnabledNextPageBtn"
                  @click="selectNextPage"
                  class="list-pages__btn list-pages__btn--next btn"
               >
                  &gt;
               </button>
            </li>
         </ul>
      </div>
   </article>
</template>

<script>
   export default {
      name: 'AppPagination',

      props: {
         routeName: { type: String, required: true },
         itemsCount: { type: Number, required: true },
         maxItemsCountOnPage: { type: Number, required: true }
      },

      inject: ['backToTopOfPage'],

      data() {
         return {
            displayedPagesCount: 3,
            offset: 3
         };
      },

      methods: {
         setPageNumber(page) {
            this.$router.push({ name: this.routeName, query: { ...this.$route.query, page } });

            setTimeout(this.backToTopOfPage, 400, 'smooth'); //проблема
         },

         selectPrevPage() {
            this.setPageNumber(this.currentPage - 1);
         },

         selectNextPage() {
            this.setPageNumber(this.currentPage + 1);
         },

         moveBackFewPages() {
            this.setPageNumber(this.currentPage - this.offset);
         },

         moveForwardFewPages() {
            this.setPageNumber(this.currentPage + this.offset);
         }
      },

      computed: {
         currentPage() {
            return +this.$route.query.page;
         },

         pages() {
            return [...new Array(Math.ceil(this.itemsCount / this.maxItemsCountOnPage))].map(
               (item, idx) => idx + 1
            );
         },

         startIdx() {
            return this.currentPage - this.displayedPagesCount <= 0
               ? 0
               : this.currentPage + this.displayedPagesCount > this.pages.length
               ? this.pages.length - this.displayedPagesCount - 1
               : this.pages.indexOf(this.currentPage) - 1;
         },

         endIdx() {
            return this.currentPage + this.displayedPagesCount > this.pages.length
               ? this.pages.length
               : this.startIdx
               ? this.startIdx + this.displayedPagesCount
               : this.startIdx + this.displayedPagesCount + 1;
         },

         displayedPages() {
            return this.pages.slice(this.startIdx, this.endIdx);
         },

         isEnabledPrevPageBtn() {
            return this.currentPage === 1;
         },

         isEnabledNextPageBtn() {
            return this.currentPage === this.pages[this.pages.length - 1];
         },

         isEnabledQuickReturnBtn() {
            return this.startIdx !== 0;
         },

         isEnabledFastForwardBtn() {
            return this.endIdx !== this.pages.length;
         }
      },

      watch: {
         currentPage: {
            handler(value) {
               if ((!value || value <= 0) && this.$route.name === this.routeName) {
                  this.$router.replace({
                     name: this.routeName,
                     query: { ...this.$route.query, page: 1 }
                  });
               }
            },
            immediate: true
         }
      }
   };
</script>
